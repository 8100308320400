














import { computed, defineComponent, reactive } from "@vue/composition-api";
import useWatchPropsValue from "@/apps/core/components/useWatchPropsValue";
import { GenericListAPI } from "@/apps/core/api/list";

export default defineComponent({
  name: "GenericFilterSelect",
  props: {
    apiPath: { type: String, required: true },
    field: { type: String },
    value: { type: String },
  },
  setup(props, { emit }) {
    const { valueRef } = useWatchPropsValue(props);
    // const options = reactive([] as Array<Record<string, string>>);
    const placeholder = props.field
      ? props.field[0].toUpperCase() + props.field.slice(1)
      : null;
    const api = new GenericListAPI(props.apiPath);
    const computedValue = computed({
      get() {
        return valueRef.value ? valueRef.value.toString() : null;
      },
      set(value: string) {
        valueRef.value = value;
        const realValue = value ? value.toString() : null;
        emit("input", realValue);
      },
    });

    api.fetch();
    const options = reactive(api.options);

    return {
      // Data
      options,
      placeholder,

      // Computed
      computedValue,
    };
  },
});
